
import { Component, Vue } from 'vue-property-decorator'
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口
import * as echarts from 'echarts/core'
// 引入柱状图图表、折线图图表，图表后缀都为 Chart
import { BarChart, LineChart } from 'echarts/charts'
import { apiWorkbenchIndex } from '@/api/home'
// 绘图网格、标题、图例、极坐标系组件
import {
  GridComponent,
  TitleComponent,
  LegendComponent,
  PolarComponent
} from 'echarts/components'
// 注意，新的接口中默认不再包含 Canvas 渲染器，需要显示引入，如果需要使用 SVG 渲染模式则使用 SVGRenderer
import { CanvasRenderer } from 'echarts/renderers'

echarts.use([
  BarChart,
  GridComponent,
  CanvasRenderer,
  TitleComponent,
  LegendComponent,
  PolarComponent,
  LineChart
])
@Component
export default class Home extends Vue {
  // 商品
  countGoods = 0
  currentPageGoods = 1
  pageSizeGoods = 10

  // 用户
  countUser = 0
  currentPageUser = 1
  pageSizeUser = 10

  // 访客图表
  visitor = {
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: ['访客']
    },
    xAxis: {
      type: 'category',
      data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
      splitLine: {
        show: true, // 是否显示分隔线。默认数值轴显示，类目轴不显示
        interval: '1' // 坐标轴刻度标签的显示间隔，在类目轴中有效.0显示所有
      }
    },
    yAxis: {
      // type: "category"
    },
    series: [
      {
        name: '访客',
        type: 'line',
        stack: '总量',
        data: []
      }
    ]
  }

  // 营业额图表
  business = {
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: ['营业额', '商品数量', '订单数量']
    },
    xAxis: {
      type: 'category',
      data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
      splitLine: {
        show: true, // 是否显示分隔线。默认数值轴显示，类目轴不显示
        interval: '1' // 坐标轴刻度标签的显示间隔，在类目轴中有效.0显示所有
      }
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        name: '营业额',
        type: 'line',
        stack: '总量',
        data: []
      },
      {
        name: '商品数量',
        type: 'line',
        stack: '总量',
        data: []
      },
      {
        name: '订单数量',
        type: 'line',
        stack: '总量',
        data: []
      }
    ]
  }

  // 用户购买能力
  indexData: any = {
    shop_info: {
      logo: ''
    },
    today: {
      today_order_num: '',
      today_order_amount: '',
      today_new_user: '',
      today_visitor: ''
    },
    pending: {
      wait_shipped: '',
      wait_audit: '',
      wait_reply: '',
      no_stock_goods: ''
    }
  }

  // 商品
  handleSizeChangeGoods (size: any) {
    this.pageSizeGoods = size
  }

  handleCurrentChangeGoods (page: any) {
    this.currentPageGoods = page
  }

  // 用户
  handleSizeChangeUser (size: any) {
    this.pageSizeUser = size
  }

  handleCurrentChangeUser (page: any) {
    this.currentPageUser = page
  }

  // 初始化首页工作台数据
  getWorkbenchIndexData () {
    // 清空 echarts 数据
    this.business.xAxis.data = []
    this.business.legend.data = []
    // this.business.series = [];

    apiWorkbenchIndex({}).then((res) => {
      this.indexData = res
      this.countGoods = this.indexData.top_goods50.length
      this.countUser = this.indexData.top_user50.length

      res.business15.list[0].data = res.business15.list[0].data.reverse()
      res.visitor15.list[0].data = res.visitor15.list[0].data.reverse()
      this.business.xAxis.data = res.business15.date.reverse()
      this.visitor.xAxis.data = res.visitor15.date.reverse()

      res.business15.list.forEach((item: any, index: number) => {
        this.business.series[index].data = item.data
        this.business.series[index].name = item.name
        this.business.legend.data[index] = item.name
      })

      res.visitor15.list.forEach((item: any, index: number) => {
        this.visitor.series[index].data = item.data
        this.visitor.series[index].name = item.name
      })
    })
  }

  // 根据指定路由进行跳转
  goPage (url: string) {
    this.$router.push({
      path: url
    })
  }

  created () {
    this.getWorkbenchIndexData()
  }
}
